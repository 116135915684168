<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll">
		<BHLoading :show="loading" />
		<h4 class="mb-3"> Settings</h4>
		<SettingsTab @menuClick="onTabChange" :menuList="menuList" :activeTab="activeTab">
			<a-card v-if="activeTab === 'profile'">
				<div class="dF aC jSB">
					<h4 class="text-dark">
						Business Profile
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								Notice that it takes 24hrs. or longer for approval of Business Profile.
							</template>
							<a-icon type="question-circle" style="font-size: 16px; color: black; margin-left: 5px;" />
						</a-tooltip>
					</h4>
					<small v-if="profileStatus" style="font-weight:300; background: var(--light-gray); border-radius:40px;"
						class="px-3 py-2 inline-block ml-3">
						<a-badge v-if="profileStatus === 'pending-review'" color="purple" text="Pending Review" />
						<a-badge v-else-if="profileStatus === 'in-review'" color="orange" text="Under Review" />
						<a-badge v-else-if="profileStatus === 'twilio-approved'" color="green" text="Approved" />
						<a-badge v-else-if="profileStatus === 'twilio-rejected'" color="red" text="Rejected" />
					</small>
				</div>
				<a-form-model ref="profile" :model="profile" class="profile">
					<hr style="margin-left: -24px; margin-right: -24px" />
					<h6 class="mb-2 mt-2 text-dark">Business Information</h6>
					<hr style="margin-left: -24px; margin-right: -24px" />
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Business Name" required prop="business_information.business_name"
								:rules="req('Please enter the Name of your Business')">
								<a-input v-model="profile.business_information.business_name" placeholder="Business Name"
									size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Website URL" required prop="business_information.website_url"
								:rules="req('Please enter the website URL of the business')">
								<a-input v-model="profile.business_information.website_url" placeholder="Website URL"
									size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Business Type" required prop="business_information.business_type"
								:rules="req('Please select the type of your Business')">
								<a-select v-model="profile.business_information.business_type" placeholder="Business Type"
									size="large">
									<a-select-option v-for="(type, index) in businessTypes" :key="index" :value="type">{{
										type }}</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Business Industry" required
								prop="business_information.business_industry"
								:rules="req('Please select the your Business Industry')">
								<a-select v-model="profile.business_information.business_industry"
									placeholder="Business Industry" size="large">
									<a-select-option v-for="(industry, index) in businessIndustries" :key="index"
										:value="industry">{{ industry.replaceAll('_', ' ') }}</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Business Registration Identifier" required
								prop="business_information.business_registration_identifier"
								:rules="req('Please select the your Business Registration Identifier')">
								<a-select v-model="profile.business_information.business_registration_identifier"
									placeholder="Business Registration Identifier" size="large">
									<a-select-option v-for="(identifier, index) in businessRegistrationIdentifiers"
										:key="index" :value="identifier">{{
											identifier }}</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Business Registration Number" required
								prop="business_information.business_registration_number"
								:rules="req('Please enter the Business Registration Number')">
								<a-input v-model="profile.business_information.business_registration_number"
									placeholder="Business Registration Number" size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Business Region of Operation" required
								prop="business_information.business_regions_of_operation"
								:rules="req('Please select the Business Regions of Operation')">
								<a-select v-model="profile.business_information.business_regions_of_operation"
									placeholder="Business Regions of Operation" size="large">
									<a-select-option v-for="(region, index) in businessRegions" :key="index"
										:value="region">{{ region.replaceAll('_', ' ') }}</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Social Media Profile Urls"
								prop="business_information.social_media_profile_urls">
								<a-select v-model="profile.business_information.social_media_profile_urls" mode="tags"
									placeholder="Social Media Profile Urls" size="large"></a-select>
							</a-form-model-item>
						</a-col>
					</a-row>
					<hr style="margin-left: -24px; margin-right: -24px" />
					<h6 class="mb-2 mt-2 text-dark">Business Address</h6>
					<hr style="margin-left: -24px; margin-right: -24px" />
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Name" required prop="business_address.customerName"
								:rules="req('Please enter the Name')">
								<a-input v-model="profile.business_address.customerName" placeholder="Name" size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Street" required prop="business_address.street"
								:rules="req('Please enter the name of street')">
								<a-input v-model="profile.business_address.street" placeholder="Street" size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="City" required prop="business_address.city"
								:rules="req('Please enter the name of city')">
								<a-input v-model="profile.business_address.city" placeholder="city" size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Region" required prop="business_address.region"
								:rules="req('Please enter the name of region')">
								<a-input v-model="profile.business_address.region" placeholder="Region" size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Postal Code" required prop="business_address.postalCode"
								:rules="req('Please enter the Postal Code')">
								<a-input v-model="profile.business_address.postalCode" placeholder="Postal Code"
									size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Country" required prop="business_address.isoCountry"
								:rules="req('Please select the Country')">
								<a-select show-search size="large" class="dropdown-style"
									v-model="profile.business_address.isoCountry" optionFilterProp="label">
									<a-select-option v-for="country in countryList" :key="country.name"
										:value="country.value2" :label="country.name">
										{{ country.name }}
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
					</a-row>
					<hr style="margin-left: -24px; margin-right: -24px" />
					<h6 class="mb-2 mt-2 text-dark">Authorized Business Representative</h6>
					<hr style="margin-left: -24px; margin-right: -24px" />
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="First Name" required prop="authorized_representative_one.first_name"
								:rules="req('Please enter the First Name')">
								<a-input v-model="profile.authorized_representative_one.first_name" placeholder="First Name"
									size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Last Name" required prop="authorized_representative_one.last_name"
								:rules="req('Please enter the Last Name')">
								<a-input v-model="profile.authorized_representative_one.last_name" placeholder="Last Name"
									size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Email" required prop="authorized_representative_one.email"
								:rules="req('Please enter the Email')">
								<a-input v-model="profile.authorized_representative_one.email" placeholder="Email"
									size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Phone Number" required
								prop="authorized_representative_one.phone_number"
								:rules="req('Please enter the Phone Number')">
								<a-input v-model="profile.authorized_representative_one.phone_number"
									placeholder="Phone Number" size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Business Title" required
								prop="authorized_representative_one.business_title"
								:rules="req('Please enter the Business Title')">
								<a-input v-model="profile.authorized_representative_one.business_title"
									placeholder="Business Title" size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Job Position" required
								prop="authorized_representative_one.job_position"
								:rules="req('Please select the Job Position')">
								<a-select v-model="profile.authorized_representative_one.job_position"
									placeholder="Job Position" size="large">
									<a-select-option v-for="(job, index) in JobPositions" :key="index" :value="job">{{
										job.replaceAll('_', ' ') }}</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
					</a-row>
				</a-form-model>
				<div class="w-full mt-3">
					<div class="pull-right">
						<a-button type="primary" @click="saveProfile" size="large">SUBMIT</a-button>
					</div>
				</div>
			</a-card>
			<a-card v-else-if="activeTab === 'phone'" title="Rent Phone Number">
				<a-table :rowKey="(e) => e.phoneNumber" class="white-table" :columns="columns"
					:data-source="activePhoneNumbers">
					<div slot="phoneNumber" slot-scope="obj">
						<div>{{ obj.phoneNumber }}</div>
					</div>
					<div slot="friendlyName" slot-scope="obj">
						<div>{{ obj.friendlyName }}</div>
					</div>
					<div slot="dateRented" slot-scope="record" class="dF">
						<span>{{ formatDate(record.createdAt) }}</span>
					</div>
					<div slot="lastUpdated" slot-scope="record" class="dF">
						<span>{{ formatDate(record.updatedAt) }}</span>
					</div>
				</a-table>
				<div class="dF aC mt-3" style="gap: 20px">
					<div @click="rentNew" class="dF aC p-2" style="color: var(--orange); cursor: pointer; gap: 20px"
						:style="!profileStatus || !isProfileApproved ? 'background-color:#f1f1f1; cursor: not-allowed' : ''">
						<a-icon style="font-size: 25px" type="plus-circle" />
						<span>Rent New Phone Number</span>
					</div>
					<a-alert v-if="!profileStatus" type="error" show-icon style="width: fit-content;">
						<template slot="message">
							Please complete your business profile from
							<span class="cursor-pointer" style="color: var(--primary)" @click="onTabChange('profile')">here
							</span> to rent a new phone number
						</template>
					</a-alert>
					<a-alert v-else-if="!isProfileApproved" type="warning" show-icon style="width: fit-content;">
						<template slot="message">
							Your business profile is not yet verified. Please wait for few days or contact
							administrator.
						</template>
					</a-alert>
				</div>
			</a-card>
		</SettingsTab>
		<a-modal v-model="rentPhoneNumber.modal" title="Rent Phone Number" @ok="rentNumber" :centered="true">
			<a-form-model ref="rentPhoneNumber" :model="rentPhoneNumber" class="profile">
				<a-form-model-item label="Select Phone Number you want to rent" required
					:rules="{ required: true, message: 'Please select a phone number you want to rent' }" prop="number">
					<a-select v-model="rentPhoneNumber.number" size="large" option-label-prop="label" showSearch>
						<a-select-option v-for="p in phoneNumbers" :key="p.phoneNumber" :value="p.phoneNumber"
							:label="p.phoneNumber">
							{{ p.phoneNumber }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-alert type="info" message="You will be charged $10/month for renting a phone number." show-icon />
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import { formatDate } from "bh-mod";
import BHLoading from 'bh-mod/components/common/Loading'
import SettingsTab from 'bh-mod/components/common/SettingsTab'
import moment from "moment";
import { countryList } from "bh-mod/countryList";

export default {
	components: {
		SettingsTab,
		BHLoading,
	},
	data() {
		return {
			loading: false,
			activeTab: 'profile',
			countryList: countryList,
			rentPhoneNumber: {
				modal: false,
				number: null
			},
			phoneNumbers: [],
			menuList: [
				{
					label: 'Profile & Phone Number Settings', id: 'sub1', children: [
						{ label: 'Business Profile', id: 'profile' },
						{ label: 'Rent Phone Number', id: 'phone' },
					]
				},
			],
			profile: {
				business_information: {
					business_name: "",
					social_media_profile_urls: [],
					website_url: "",
					business_regions_of_operation: "USA_AND_CANADA",
					business_type: "Partnership",
					business_registration_identifier: "EIN",
					business_industry: "REAL_ESTATE",
					business_registration_number: ""
				},
				business_address: {
					customerName: "",
					street: "",
					city: "",
					region: "",
					postalCode: "",
					isoCountry: ""
				},
				authorized_representative_one: {
					job_position: "CEO",
					last_name: "",
					phone_number: "",
					first_name: "",
					email: "",
					business_title: ""
				}
			},
			columns: [
				{
					title: "Phone Number",
					key: "phoneNumber",
					scopedSlots: { customRender: "phoneNumber" },
					sorter: (a, b) => a < b ? -1 : a > b ? 1 : 0,
				},
				{
					title: "Date Rented",
					scopedSlots: { customRender: "dateRented" },
					key: "dateRented",
					sorter: (a, b) =>
						moment(a.createdAt).format("x") -
						moment(b.createdAt).format("x"),
				},
				{
					title: "Last Updated",
					scopedSlots: { customRender: "lastUpdated" },
					key: "lastUpdated",
					sorter: (a, b) =>
						moment(a.updatedAt).format("x") -
						moment(b.updatedAt).format("x"),
				},
				{
					title: "",
					key: "actions",
					scopedSlots: { customRender: "actions" },
				},
			],
			businessTypes: ["Partnership", "Limited Liability Corporation", "Co-operative", "Non-profit Corporation", "Corporation"],
			businessIndustries: ["AUTOMOTIVE", "AGRICULTURE", "BANKING", "CONSTRUCTION", "CONSUMER", "EDUCATION", "ENGINEERING", "ENERGY", "OIL_AND_GAS", "FAST_MOVING_CONSUMER_GOODS", "FINANCIAL", "FINTECH", "FOOD_AND_BEVERAGE", "GOVERNMENT", "HEALTHCARE", "HOSPITALITY", "INSURANCE", "LEGAL", "MANUFACTURING", "MEDIA", "ONLINE", "PROFESSIONAL_SERVICES", "RAW_MATERIALS", "REAL_ESTATE", "RELIGION", "RETAIL", "JEWELRY", "TECHNOLOGY", "TELECOMMUNICATIONS", "TRANSPORTATION", "TRAVEL", "ELECTRONICS", "NOT_FOR_PROFIT"],
			businessRegistrationIdentifiers: ["EIN", "DUNS", "CBN", "CN", "ACN", "CIN", "VAT", "VATRN", "RN", "Other"],
			businessRegions: ["AFRICA", "ASIA", "EUROPE", "LATIN_AMERICA", "USA_AND_CANADA"],
			JobPositions: ["Director", "GM", "VP", "CEO", "CFO", "General Counsel", "Other"]
		};
	},
	computed: {
		instance() {
			return this.$store.state.instance;
		},

		activePhoneNumbers() {
			return this.$store.state.sms.activePhoneNumbers;
		},

		profileStatus() {
			return this.instance && this.instance.twilioApplicationToPersonData && this.instance.twilioApplicationToPersonData.twilioCustomerProfile && this.instance.twilioApplicationToPersonData.twilioCustomerProfile.status
		},

		isProfileApproved() {
			return this.profileStatus === 'twilio-approved'
		}
	},
	methods: {
		moment, formatDate,

		req: (msg) => ({ required: true, message: msg }),

		onTabChange(e) {
			this.activeTab = e
		},

		saveProfile() {
			this.$refs.profile.validate((valid) => {
				if (valid) {
					let payload = JSON.parse(JSON.stringify(this.profile))
					payload.business_information.social_media_profile_urls = payload.business_information.social_media_profile_urls.join(', ')
					this.$api.post(`/sms-broadcasts/:instance/register-customer-profile`, payload)
						.then(() => {
							this.$message.success("You have successfully submitted profile.");
						})
						.catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err, `An error occurred while saving your transaction as a draft. Please try again.`))
							}
						});
				} else {
					return false;
				}
			});
		},

		async rentNew() {
			if (!this.profileStatus || !this.isProfileApproved) {
				return this.$message.error('Your Profile is not yet created or approved. You can only rent a phone number once your profile is created and approved.');
			}
			if (this.phoneNumbers && !this.phoneNumbers.length) {
				await this.searchPhoneNumber()
			}
			this.rentPhoneNumber.id = null;
			this.rentPhoneNumber.modal = true;
			this.$nextTick(() => {
				this.$refs.rentPhoneNumber.clearValidate();
			})
		},

		rentNumber() {
			let self = this;
			this.$refs.rentPhoneNumber.validate(valid => {
				if (valid) {
					self.loading = true;
					let payload = this.phoneNumbers.find(p => p.phoneNumber == self.rentPhoneNumber.number)
					if (!payload) {
						return false;
					}
					self.$api
						.post(`sending-phone-numbers/:instance`, payload)
						.then(() => {
							self.$message.success("Successfully rented selected phone number.");
						})
						.catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err, `An error occurred while renting number. Please try again.`))
							}
						})
						.finally(() => {
							self.loading = false;
						});
				} else {
					return false;
				}
			});
		},

		async searchPhoneNumber() {
			try {
				const { data } = await this.$api.get(`sending-phone-numbers/search`);
				this.phoneNumbers = data;
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching phone number list. Please try again.`))
				}
			}
		}
	},
	created() {
		this.$store.commit("SMS_TYPE", { value: 'Settings' })
	},
};
</script>
